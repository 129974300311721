<template>
  <div>
      <el-row>
          <el-col :span="16" :xs="{span:24,offset:0}" :offset="4">
              <div class="form">
                  <div class="titleBox ">{{title}}</div>
                  <el-col :span="24">
                      <div class="content">
                          <slot></slot>
                      </div>
                  </el-col>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  name: "FormCard",
  props: {
    title: String
  }
};
</script>

<style scoped lang="scss">
.form {
  margin: 13px auto 131px auto;
  .titleBox {
    background: url("~@/assets/img/account/title-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 161px;
    line-height: 121px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
  .content {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 56px;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
  }
  .another {
    color: dodgerblue;
  }
  .serviceDec {
    color: #43b6ff;
  }
  .agreeButton {
    margin: auto;
  }
}
</style>