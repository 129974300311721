<template>
  <div class="pageBox">
    <div class="content-block">
      <form-card title="找回密码">
        <el-form
          class="formBox"
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          @validate="formValidate"
        >
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="邮箱" prop="email">
                <!-- 禁止浏览器自动填充密码，勿删-->
                <input type="password" style="position:absolute;width:0;height:0;z-index: -999" />
                <el-input
                  aria-label="邮箱"
                  aria-autocomplete="none"
                  clearable
                  placeholder="请输入邮箱地址"
                  type="text"
                  v-model="form.email"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10 imgCaptchaBox">
            <el-col :span="12" :offset="4" :xs="{span:14,offset:0}">
              <el-form-item label="图片验证码" prop="imgCaptcha">
                <el-input
                  aria-label="图片验证码"
                  aria-autocomplete="none"
                  clearable
                  maxlength="4"
                  aria-valuemax="4"
                  show-word-limit
                  placeholder="请输入图片中的验证码"
                  v-model="form.imgCaptcha"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1" :xs="{span:7}">
              <img class="verifiImg" :src="imgCaptchaInfo.img" alt="图片验证码" />
              <div role="button" class="refresh cursor" @click="getImgCaptcha()">点击刷新</div>
            </el-col>
          </el-row>
          <el-row class="margin-b10 captchaCodeBox">
            <el-col :span="12" :offset="4" :xs="{span:14,offset:0}">
              <el-form-item label="验证码" prop="emailCaptcha">
                <el-input
                  aria-label="验证码"
                  aria-autocomplete="none"
                  clearable
                  v-model="form.emailCaptcha"
                  placeholder="请输入邮箱收到的验证码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1" :xs="{span:7,}">
              <el-button
                v-if="count==60"
                class="captchaCodeBtn"
                :class="{'btnDisabled':!formValid.email || !formValid.imgCaptcha}"
                @click="(formValid.email && formValid.imgCaptcha) && sendCode()"
              >发送验证码</el-button>
              <div v-else class="sendCodeButton cursor btnDisabled">请{{count}}s后再试</div>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="密码" prop="password">
                <el-input
                  aria-label="密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="form.password"
                  placeholder="请填写密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="确认密码" prop="passwordConfirm">
                <el-input
                  aria-label="确认密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="form.passwordConfirm"
                  placeholder="请再次填写密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item class="text-center">
                <div
                  class="btn"
                  :class="{btnDisabled:Object.keys(formValid).length != Object.keys(rules).length}"
                  @click="Object.keys(formValid).length == Object.keys(rules).length && submitForm('form')"
                >提 交</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </form-card>
    </div>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import { singleAccountSever } from "@/common/api-config";
import FormCard from "@/components/FormCard.vue";

export default {
  components: { FormCard },
  created() {
    this.getImgCaptcha(); //获取验证码
  },
  data() {
    //邮箱检验
    var confirmEmail = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入邮箱地址"));
      } else {
        singleAccountSever
          .checkEmail({
            email: this.form.email
          })
          .then(res => {
            if (res.code == 11005) {
              //已注册
              callback();
            } else {
              callback("邮箱未注册");
            }
          });
      }
    };
    //验证码检验
    var checkImgCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入图形中的验证码"));
      } else if (value.length == 4) {
        singleAccountSever
          .checkImgCaptcha({
            imgCaptcha: value,
            ckey: this.imgCaptchaInfo.key
          })
          .then(res => {
            if (res.code == 0) {
              callback();
            } else if (res.code == 1019) {
              // 图片验证码过期，需要重新获取图片验证码
              this.getImgCaptcha();
              callback(new Error("图形验证码已失效"));
            } else {
              callback(new Error("图形验证码输入错误"));
            }
          });
      } else {
        callback(new Error("图片验证码错误"));
      }
    };
    //2次确定密码
    var confirmPassWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      count: 60, // 倒计时
      imgCaptchaInfo: {
        //图片验证码数据
        key: "",
        img: ""
      },
      form: {
        //表单
        email: "", //邮箱
        imgCaptcha: "", //图片验证码
        emailCaptcha: "", //邮箱验证码
        password: "", //密码
        passwordConfirm: "" //确认密码
      },
      formValid: {}, //用于检验form是否通过
      rules: {
        //表单验证规则
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入合法的邮箱地址", trigger: "blur" },
          { required: true, validator: confirmEmail, trigger: "blur" }
        ],
        imgCaptcha: [
          {
            required: true,
            validator: checkImgCaptcha,
            trigger: ["change"]
          }
        ],
        emailCaptcha: [
          { required: true, message: "请输入邮箱收到的验证码", trigger: "blur" }
        ],
        password: [
          { required: true, validator: passWord, trigger: ["blur", "change"] }
        ],
        passwordConfirm: [
          {
            required: true,
            validator: confirmPassWord,
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  methods: {
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
    //提交
    submitForm() {
      singleAccountSever
        .backPassword({
          email: this.form.email, //邮箱
          ckey: this.imgCaptchaInfo.key, //验证key
          imgCaptcha: this.form.imgCaptcha, //图片验证码
          emailCaptcha: this.form.emailCaptcha, //邮箱验证码
          password: this.$md5(this.form.password + this.configBaseSALT), //密码
          passwordConfirm: this.$md5(
            this.form.passwordConfirm + this.configBaseSALT
          ) //确认密码
        })
        .then(result => {
          if (result.code == 0) {
            this.$message.success("成功");
            this.$router.push({ path: "/account/login" });
          } else {
            this.$message.error(result.message);
            this.getImgCaptcha();
          }
        });
    },
    //获取图片验证码
    getImgCaptcha() {
      singleAccountSever.getImgCaptcha().then(result => {
        if (result.code == 0) {
          this.imgCaptchaInfo = result.data[0].captcha; //图片验证码数据
        } else if (result.code == 1019) {
          this.getImgCaptcha();
        } else {
          this.$message.error("获取验证图片失败");
        }
      });
    },
    //发送验证码
    sendCode() {
      singleAccountSever
        .sendEmailCaptcha({
          email: this.form.email,
          type:4,
        })
        .then(result => {
          if (result.code == 0) {
            //倒计时
            this.timer = setInterval(() => {
              this.count--;
              if (this.count < 0) {
                clearInterval(this.timer);
                this.count = 60;
              }
            }, 1000);
          } else {
            this.$message.error("发送失败");
            this.getImgCaptcha();
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: rgba(40, 38, 44, 1);
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  cursor: pointer;
}
.btnDisabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.pageBox {
  height: 100%;
  padding: 60px 0px 70px 0px;
  background-color: #f8f6f7;
  .formBox {
    margin: auto;
    .imgCaptchaBox {
      .verifiImg {
        display: block;
        width: 100%;
        height: 36px;
        border-radius: 4px;
      }
      .refresh {
        line-height: 30px;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .captchaCodeBox {
      .captchaCodeBtn {
        width: 100%;
        padding: 12px 0 !important;
        background: rgba(40, 38, 44, 1) !important;
        color: #fff !important;
      }
      .sendCodeButton {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        text-align: center;
        background: rgba(40, 38, 44, 1);
        box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
        color: #ffffff;
      }
    }
  }
}
</style>